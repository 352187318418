import React from 'react';
import { Link } from 'gatsby';
import { useSiteContext, useSubscriptionOpen } from '../../../lib/context';
import { SanityImage } from '../../sanity/sanityImage';

export const StandardCard = ({ accentColor, cardType, className, ...rest }) => {
  const {
    featuredImage,
    excerpt,
    dateRange,
    slug,
    title,
    location,
    category,
    position,
    photo,
    name,
    bio,
    sessions,
    endDate,
    series,
    page,
    specialConcert,
    startDate,
  } = cardType ? cardType : rest;

  const teal = '#036483';
  const tealBg = '#03648320';

  const hoverStyles = `
        .${slug?.current || page?.slug?.current}{
            background: ${accentColor ? accentColor?.hex + '20' : tealBg};
            color: ${accentColor?.hex || teal};
        }
        .${slug?.current || page?.slug?.current}:hover{
            background: ${accentColor ? accentColor?.hex : teal};
        }
    `;

  const seriesTag = specialConcert
    ? { text: 'SPECIAL CONCERT', color: '#9B60BE' }
    : series
    ? series === 'cmnz'
      ? { text: 'CMNZ SERIES', color: '#E73F60' }
      : { text: 'IN PARTNERSHIP', color: '#F47F20' }
    : null;

  //check if startDate is in season
  const isInSeason = new Date(startDate) >= new Date('2025-01-01');
  const showBookSeason = true;

  const { state } = useSiteContext();
  const toggleSubscriptionOpen = useSubscriptionOpen();

  return new Date(endDate) > new Date() || !endDate ? (
    <div
      className={`${className} w-[330px] md:w-[500px] !bg-white self-stretch rounded-3xl relative z-10 overflow-hidden md:hover:scale-[1.015] transition-standard`}
      style={{ flex: '0 0 auto' }}>
      <style>{hoverStyles}</style>
      <div className='flex flex-col justify-between h-full items-center gap-y-4 lg:gap-y-8'>
        <div className='w-full'>
          <div className='aspect-w-5 aspect-h-3 relative w-full overflow-hidden'>
            <Link to={`/${slug?.current || page?.slug?.current}`}>
              <SanityImage
                image={featuredImage || photo}
                className='w-full h-full absolute top-0 left-0 object-cover scale-[1.01] activate-cursor'
              />
            </Link>
          </div>
          <div
            className='px-6 md:px-10 pt-6 md:pt-10'
            style={{ color: accentColor?.hex || teal }}>
            <div className='flex flex-col xl:flex-row xl:items-center gap-5'>
              {seriesTag && (
                <span
                  style={{
                    backgroundColor: accentColor?.hex || seriesTag?.color,
                  }}
                  className={`text-white rounded-full blockH9 font-bold px-[14px] py-2 w-max`}>
                  {seriesTag?.text}
                </span>
              )}
              <span className='blockH7 font-bold'>
                {dateRange || position || location || category?.title}
              </span>
            </div>
            <h3 className='blockH4 my-3'>{title || name}</h3>
            <p className='blockH8 text-grey overflow-elipsis-5'>
              {excerpt || bio}
            </p>
          </div>
        </div>
        <div className='px-6 md:px-10 pt-3 md:pt-5 pb-6 md:pb-10 flex items-center gap-x-3 justify-between md:justify-start w-full'>
          {(slug || page?.slug?.current) && (
            <Link
              to={`/${slug?.current || page?.slug?.current}`}
              className={`btn-standard ${
                slug?.current || page?.slug?.current
              }`}>
              Tell me more
            </Link>
          )}
          {isInSeason && series !== 'partnership' && showBookSeason ? (
            <button
              role='button'
              onClick={() => {
                toggleSubscriptionOpen(!state.subscriptionOpen);
              }}
              className={`btn-standard cursor-pointer ${slug?.current}`}>
              Book tickets
            </button>
          ) : (
            sessions?.length > 0 && (
              <Link
                to={`/${slug?.current}#tickets`}
                className={`btn-standard ${slug?.current}`}>
                Buy tickets
              </Link>
            )
          )}
        </div>
      </div>
    </div>
  ) : null;
};
